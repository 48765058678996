import React from 'react';
import { Typography, Tag } from 'antd';
import styled from 'styled-components';
import Helmet from '../../config/helmet';
import Container from '../components/UI/Container';

import StandardDoubleSharing from '../assets/rooms/double-sharing_standard.jpg';
import StandardTwin from '../assets/rooms/twin_standard.jpg';
import DeluxeDoubleSharing from '../assets/rooms/double-sharing_deluxe.jpg';
import DeluxeTwin from '../assets/rooms/twin_deluxe.jpg';
import DeluxeDoubleSharingVerdana from '../assets/rooms/double-sharing-verdana_deluxe.jpg';
import DeluxeFamily from '../assets/rooms/family_deluxe.jpg';
import SuperiorOne from '../assets/rooms/superior_1.jpg';
import SuperiorTwo from '../assets/rooms/superior_2.jpg';
import Barkada from '../assets/rooms/barkada_1.jpg';

const { Title } = Typography;

const Wrapper = styled(Container)`
  margin-top: 100px !important;
`;

const Content = styled.div`
  h2 {
    text-align: center;
  }

  p {
    text-align: justify;
    margin-bottom: 1.5rem !important;
  }
`;

const Rooms = styled.div`
  margin: 1.5rem 0;

  @media (min-width: 991.98px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
  }
`;

const RoomItem = styled.div`
  overflow: hidden;
  cursor: pointer;
  border: 1px Solid #eee;

  img {
    max-width: 100%;
  }

  div {
    padding: 1em;
  }

  &:hover {
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);

    img {
      transform: scale(1.05);
    }
  }

  ul {
    margin-top: 1rem;
  }

  @media (max-width: 992px) {
    margin-bottom: 1rem;
  }
`;

export default () => (
  <Wrapper>
    <Helmet title='Rooms' />

    <Title>Rooms</Title>
    <Content>
      <div>
        <Title level={3} style={{ textAlign: 'center' }}>
          Accommodation includes complimentary breakfast which the guest can choose from the breakfast menu. Guests are
          entitled to free use of the pool and cottages.
        </Title>
        <Rooms>
          <RoomItem>
            <img src={StandardDoubleSharing} alt='standard-double-sharing-room' />
            <div>
              <Title level={4} style={{ marginBottom: 0 }}>
                Standard Double Sharing GF
              </Title>
              <Tag color='#111'>PHP 3,500.00</Tag>

              <ul>
                <li>18-square meter</li>
                <li>Double-sized bed</li>
                <li>32-inch LED HD TV</li>
                <li>Private Bathroom</li>
              </ul>
            </div>
          </RoomItem>

          <RoomItem>
            <img src={StandardTwin} alt='standard-twin-room' />
            <div>
              <Title level={4} style={{ marginBottom: 0 }}>
                Standard Twin Sharing GF
              </Title>
              <Tag color='#111'>PHP 3,500.00</Tag>

              <ul>
                <li>18-square meter</li>
                <li>Single beds</li>
                <li>32-inch LED HD TV</li>
                <li>Private Bathroom</li>
              </ul>
            </div>
          </RoomItem>

          <RoomItem>
            <img src={DeluxeDoubleSharing} alt='deluxe-double-sharing-room' />
            <div>
              <Title level={4} style={{ marginBottom: 0 }}>
                Standard Double Sharing 2F
              </Title>
              <Tag color='#111'>PHP 4,000.00</Tag>

              <ul>
                <li>25-square meter</li>
                <li>Double-sized beds</li>
                <li>32-inch LED HD TV</li>
                <li>Private Bathroom</li>
                <li>Coffee Table</li>
              </ul>
            </div>
          </RoomItem>

          <RoomItem>
            <img src={DeluxeTwin} alt='deluxe-twin-room' />
            <div>
              <Title level={4} style={{ marginBottom: 0 }}>
                Standard Twin Sharing 2F
              </Title>
              <Tag color='#111'>PHP 4,000.00</Tag>

              <ul>
                <li>25-square meter</li>
                <li>Single beds beds</li>
                <li>32-inch LED HD TV</li>
                <li>Private Bathroom</li>
                <li>Coffee Table</li>
              </ul>
            </div>
          </RoomItem>

          <RoomItem>
            <img src={DeluxeDoubleSharingVerdana} alt='deluxe-double-sharing-veranda-room' />
            <div>
              <Title level={4} style={{ marginBottom: 0 }}>
                Deluxe Double Sharing with Veranda
              </Title>
              <Tag color='#111'>PHP 4,500.00</Tag>

              <ul>
                <li>25-square meter</li>
                <li>Double-sized bed</li>
                <li>32-inch LED HD TV</li>
                <li>Private Bathroom</li>
                <li>Coffee Table</li>
              </ul>
            </div>
          </RoomItem>

          <RoomItem>
            <img src={DeluxeFamily} alt='deluxe-family-room' />
            <div>
              <Title level={4} style={{ marginBottom: 0 }}>
                Deluxe Family Room
              </Title>
              <Tag color='#111'>PHP 5,000.00</Tag>

              <ul>
                <li>25-square meter</li>
                <li>Double-sized and Single bed</li>
                <li>32-inch LED HD TV</li>
                <li>Private Bathroom</li>
                <li>Coffee Table</li>
              </ul>
            </div>
          </RoomItem>

          <RoomItem>
            <img src={SuperiorOne} alt='superior-room-1' />
            <div>
              <Title level={4} style={{ marginBottom: 0 }}>
                Junior Superior Room
              </Title>
              <Tag color='#111'>PHP 5,000.00</Tag>

              <ul>
                <li>28-square meter</li>
                <li>Double-sized bed</li>
                <li>43-inch LED HD TV</li>
                <li>Private Bathroom</li>
                <li>Hair Dryer and Coffee Table</li>
              </ul>
            </div>
          </RoomItem>

          <RoomItem>
            <img src={SuperiorTwo} alt='superior-room-2' />
            <div>
              <Title level={4} style={{ marginBottom: 0 }}>
                Superior Room
              </Title>
              <Tag color='#111'>PHP 5,500.00</Tag>

              <ul>
                <li>28-square meter</li>
                <li>Queez-sized bed</li>
                <li>43-inch LED HD TV</li>
                <li>Private Bathroom</li>
                <li>Hair Dryer, Coffee Table and Mini Fridge</li>
              </ul>
            </div>
          </RoomItem>

          <RoomItem>
            <img src={Barkada} alt='barkada-room' />
            <div>
              <Title level={4} style={{ marginBottom: 0 }}>
                Barkada Room
              </Title>
              <Tag color='#111'>PHP 9,000.00</Tag>

              <ul>
                <li>36-square meter</li>
                <li>Eight (8) Single Beds</li>
                <li>32-inch LED HD TV</li>
                <li>Two (2) Private Bathrooms</li>
              </ul>
            </div>
          </RoomItem>
        </Rooms>
      </div>
    </Content>
  </Wrapper>
);
